import React from 'react'
import { Icon, Image } from 'semantic-ui-react'
import styles from './Css/footer.module.css'
import SubscribComponent from './Comon/Subscrib';

const socialIcons = [
  {
    link: '#',
    name: 'facebook',
    ariaLabel: 'link to facebook'
  },
  {
    link: '#',
    name: 'instagram',
    ariaLabel: 'link to instagram'
  },
  {
    link: '#',
    name: 'youtube',
    ariaLabel: 'link to youtube'
  },
  {
    link: '#',
    name: 'twitter square',
    ariaLabel: 'link to twitter'
  },
]

const Footer = () => (
  <footer className={`footer ${styles.footer}`}>
    <div className={`container ui three column stackable grid`}>
      <div className="column">
        <Image src="/./img/akkaLogo.png" size="small" />
        <p>
          Le Lorem Ipsum est simplement du
          faux texte employé dans la composition
          et la mise en page avant impression.
        </p>
        <div>
          {socialIcons.map((item, index) => {
            return (
              <a key={index} href={item.link} aria-label={item.ariaLabel}><Icon name={item.name} size="big" /></a>
            )
          })

          }
        </div>
      </div>
      <div className={`column ${styles.contact_info}`}>
        <h2>Contactez-nous</h2>
        <div>
          <Icon name="map marker alternate" size="big" />
          <span>Cité Petit Bois, 23 Bloc C Paradou–Hydra. Alger</span>
        </div>
        <div>
          <Icon name="phone" size="big" />
          <span>00 213 (0) 23 482 673</span>
        </div>
        <div>
          <Icon name="mobile alternate" size="big" />
          <span>00 213 (0) 550 644 602</span>
        </div>
        <div>
          <Icon name="mail" size="big" />
          <span>akkacasting@gmail.com</span>
        </div>
      </div>
      <div className={`column ${styles.subscrib}`}>
        <SubscribComponent styles={styles} />
      </div>
    </div>
  </footer>
)

export default Footer
