import React from "react";
import Link from "gatsby-link";
import { Responsive, Icon } from "semantic-ui-react";
import styles from "./Css/navbar.module.css";

const Navbar = ({ isTop, open, handleToggleMenu }) => {
  return (
    <header id={`${(isTop) ? styles.transparent_nav : styles.white_nave}`} className={styles.nav_header}>
      <div className={`container ${styles.navbar}`}>
        <Link to="/" className={styles.logo}>
        <img src="/./img/akkaLogo.png" alt="logo" style={{ width: "175px" }} />
        </Link>
        <Responsive maxWidth={991}>
          <div className={styles.mobile_trigger}>
            <Icon
              name={open ? "close" : "bars"}
              color={open ? "black" : "grey"}
              onClick={handleToggleMenu}
            />
          </div>
        </Responsive>
        <nav id="menu">
          <ul id={open ? styles.show_nav : styles.hide_nav}>
            {links.map(link => (
              <li key={link.name}>
                <Link to={link.url} activeClassName={styles.active_nav_link}>
                  {link.name}
                </Link>
              </li>
            ))}
            {
              socialMedia.map((item,index)=>(
                <li key={index} className={styles.socials_links}>
                <a href={item.link} >
                  <Icon name={item.name}/>
                </a>
              </li>
              ))
            }
          </ul>
        </nav>
      </div>
    </header>
  )
};

export default Navbar;
const links = [
  {
    name: "Accueil",
    url: "/"
  },
  {
    name: "A props",
    url: "/about"
  },
  {
    name: "Productions",
    url: "/nos-productions"
  },
  {
    name: "Rejoignez-nous",
    url: "/regoinez-nous"
  },
  {
    name: "Contact",
    url: "/contactez-nous"
  }
];
const socialMedia = [
  { name: "facebook", link: "https://www.facebook.com/" },
  { name: "twitter", link: "https://twitter.com/?lang=fr" },
  { name: "instagram", link: "https://www.instagram.com/?hl=fr" },
]
