import React from "react";
import Helmet from "react-helmet";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

class TemplateWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isTop: true
    };
    this.onScroll = this.onScroll.bind(this);
  }
  toggleMenu() {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  }
  componentDidMount() {
    document.addEventListener('scroll', this.onScroll);
  }
  onScroll() {
    const isTop = window.scrollY < 150
    this.setState({
      isTop: isTop
    })
  }
  render() {
    const { open, isTop } = this.state;
    return (
      <div id="app" className={open ? "is-menu-visible" : ""}>
        <Helmet>
          <meta http-equiv="content-language" content="fr" />
          <html lang="fr" amp />
        </Helmet>
        <Navbar
          open={this.state.open}
          handleToggleMenu={this.toggleMenu.bind(this)}
          isTop={isTop}
        />

        <div className="main">{this.props.children}</div>
        <Footer />
        <noscript>Your browser does not support JavaScript!</noscript>
      </div>
    );
  }
}

export default TemplateWrapper;
