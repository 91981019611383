import React from 'react'
import Layout from '../components/Layout'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import { Button } from 'semantic-ui-react';
import styles from '../components/Css/thanks.module.css'
import SubscribComponent from '../components/Comon/Subscrib';

const ThankYouPage = () => {
    return (
        <Layout>
            <Helmet
                title="Message envoyé avec succés"
            />
            <div className={styles.main_wrapper}>
                <h1>Votre message a étais envoyé avec succés</h1>
                <Link to="/"><Button color="red">Revenr à la page d'accueil</Button></Link>
                <div className={styles.subscribe_section}>
                    <SubscribComponent styles={styles}/>
                </div>
            </div>
        </Layout>
    )
}
export default ThankYouPage