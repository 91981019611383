import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'

class SubscribComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mail: '',
        }
        this.handleChange = this.handleChange.bind(this)
    }
    handleChange(event) {
        this.setState({ mail: event.target.value })
    }
    render() {
        const formLabel = 'Abonnez-vous pour recevoir nos nouveautés'
        const {styles} = this.props
        return (
            <div id="mc_embed_signup" className={styles.subscrib_container}>
                <form action="https://gmail.us3.list-manage.com/subscribe/post?u=ea52e564ed9dbbf939b620e61&amp;id=5645219974"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate ui form"
                    target="_blank"
                    noValidate>
                        
                    <div id="mc_embed_signup_scroll" className={styles.inputs_container}>
                        <div id={styles.mail_input_container} className={`mc-field-group field`}>
                            <label htmlFor="mce-EMAIL"><h2>{formLabel}</h2></label>
                            <input
                                type="email"
                                value={this.state.mail}
                                onChange={this.handleChange}
                                name="EMAIL"
                                className={styles.input}
                                id="mce-EMAIL"
                                placeholder="Entrez votre adresse mail"
                            />
                        </div>
                        <div id="mce-responses" className="clear">
                            <div
                                className="response"
                                id="mce-error-response"
                                style={{ display: 'none' }}
                            />
                            <div
                                className="response"
                                id="mce-success-response"
                                style={{ display: 'none' }}
                            />
                        </div>
                        <div
                            style={{ position: 'absolute', left: '-5000px' }}
                            aria-hidden="true"
                        >
                            <input
                                type="text"
                                name="b_c38651884696a9f29bdc54ee3_b54cd5da35"
                                tabIndex="-1"
                            />
                        </div>
                        <div id={styles.submit_container} className={`clear ${styles.submit_container}`}>
                            <Button
                                type="submit"
                                name="subscribe"
                                id="mc-embedded-subscribe"
                                className={styles.submit_btn}
                                color="red"
                                aria-label="Subscribe button"
                            >
                                S'abonner
              </Button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default SubscribComponent
